<script setup lang="ts">
import { mdiArrowUp } from "@mdi/js";

const { t } = useI18n();


type Props = {
  top?: string;
  right?: string;
  bottom?: string;
  left?: string;
  tabIndex?: number;
};
const props = withDefaults(defineProps<Props>(), {
  tabIndex: 1000,
});

const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: "smooth" });
};
</script>

<template>
  <hw-button
    @click="scrollToTop"
    variant="text"
    size="large"
    class="back-to-top-btn position-absolute text-uppercase w-100"
    :linkTitle="t('button.backToTop.ariaLabel')"
    :style="{
      top: top,
      right: right,
      bottom: bottom,
      left: left,
    }"
    :tabindex="tabIndex"
  >
    <v-icon :icon="mdiArrowUp" size="large" class="me-8" />
    <span class="text-no-wrap">{{ t("button.backToTop.label") }}</span>
  </hw-button>
</template>
